












import { Component, Vue } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class SignIn1 extends Vue {}
